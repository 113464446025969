/**
 * this function will search authors list returned by graph query against a given author name
 * authors: list of authors returned by the graph query
 * authorName: name of the author to search for
 */
const getAuthorImage = (authors, authorName) => {
  const author = authors.find(
    ({ node }) => node.frontmatter.name === authorName
  );
  if (author) {
    return author.node.frontmatter.featuredimage;
  }
  return null;
};

export default getAuthorImage;