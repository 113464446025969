import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Common/Layout";
import BlogRoll from "../components/Blog/BlogRoll";

const localData = {
  en: {
    title: "Brocode Studio || Blog page",
    description:
      "Here we share some of Brocode expertise and cool and innovative ideas",
  },
  pt: {
    title: "Brocode Studio || Pagina Blog",
    description:
      "Aqui, compartilhamos alguns dos conhecimentos da Brocode e ideias interessantes e inovadoras",
  },
};

const BlogIndex = ({ data, pageContext }) => {
  const { indexEN, indexPT } = data;
  const { language } = pageContext;
  const [modalVisible, setModalVisible] = useState(false);

  const readButton =
    language === "pt"
      ? indexPT.frontmatter.readButton
      : indexEN.frontmatter.readButton;

  return (
    <Layout
      selectedLanguage={language}
      location="blog"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      title={localData[language].title}
      description={localData[language].description}
    >
      <section style={{ paddingTop: "3rem" }}>
        <div className="container px-4">
          <BlogRoll language={language} readButton={readButton} />
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    indexEN: markdownRemark(
      frontmatter: { templateKey: { eq: "blog-index" }, language: { eq: "en" } }
    ) {
      frontmatter {
        language
        readButton
      }
    }
    indexPT: markdownRemark(
      frontmatter: { templateKey: { eq: "blog-index" }, language: { eq: "pt" } }
    ) {
      frontmatter {
        language
        readButton
      }
    }
  }
`;
