import React, { useState } from "react";
import PropTypes from "prop-types";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Col, Row } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import PostImage from "../PostImage";
import getAuthorImage from "../../../helpers/getAuthorImage";
import BlogRollPellet from "./BlogRollPellet";

const localData = {
  en: {
    find: "Find interviews, articles and all sorts of",
    cool: "cool stuff",
    read:
      "Read about the latest trends and techniques related to the world of UX/UI.",
  },

  pt: {
    find: "Find interviews, articles and all sorts of",
    cool: "cool stuff",
    read:
      "Read about the latest trends and techniques related to the world of UX/UI.",
  },
};

const types = ["View all", "Articles", "Interviews"];

const BlogRoll = ({ postsData, authorsData, language, readButton }) => {
  const { edges: posts } = postsData;
  const { edges: authors } = authorsData;
  const [selectedType, setSelectedType] = useState("View all");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const filteredPosts = posts.filter(({ node: post }) => {
    console.log(
      selectedType.toLowerCase().substring(0, selectedType.length - 1)
    );
    if (selectedType === types[0]) {
      return true;
    } else {
      return (
        post.frontmatter.type ===
        selectedType.toLowerCase().substring(0, selectedType.length - 1)
      );
    }
  });

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const { result } = await addToMailchimp(email);
  //     if (result === "error") {
  //       setLoading(false);
  //       setEmail("");
  //     } else {
  //       setModalVisible(true);
  //       setEmail("");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setEmail("");
  //     console.log("Error:", error);
  //   }
  // };

  return (
    <>
      <section>
        <Col xs={12} className="mt-100 blog-header">
          <div className="section-title--between wow move-up w-100 mt--40">
            <div className="title w-100">
              <h3 className="w-100 text-center">
                {localData[language].find}
                <span className="theme-creative">
                  {" " + localData[language].cool}
                </span>
              </h3>
              <p className="text-center">{localData[language].read}</p>
              {/* <div className="subscribe-form">
                <p>{getNotified}</p>
                <form onSubmit={onSubmit}>
                  <input
                    type="email"
                    required
                    placeholder={modalInputPlaceholder}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="submit"
                    className={`broCode-button__red button ${
                      loading ? "is-loading" : ""
                    }`}
                  >
                    {subscribeButton}
                  </button>
                </form> </div>*/}
            </div>
          </div>
        </Col>
      </section>
      <section className="article-type-selector d-flex">
        {types.map((type) => (
          <div
            onClick={() => setSelectedType(type)}
            className={`type ${selectedType === type && "active-type"}`}
          >
            {type}
          </div>
        ))}
      </section>
      <section>
        <Row className="blog-roll-article">
          {posts &&
            filteredPosts
              .filter(
                ({ node: post }) => post.frontmatter.language === language
              )
              .map(({ node: post }, id) => (
                <Col lg={6} xs={12} className="mb-lg-1 mb-5 mt-5 px-lg-5">
                   <AniLink
                    cover
                    direction="right"
                    to={post.fields.slug}
                    bg="#fa4180"
                  >
                  {getAuthorImage(authors, post.frontmatter.author) ? (
                    <PostImage
                      image={getAuthorImage(authors, post.frontmatter.author)}
                      alt={`featured image thumbnail for post ${post.frontmatter.author}`}
                      type={post.frontmatter.type}
                    />
                  ) : null}
                  <BlogRollPellet type={post.frontmatter.type} />
                  <h1 className="broCode-header">{post.frontmatter.title}</h1>
                  <h6 className="mb-3 broCode-description">
                    {post.frontmatter.description}
                  </h6>
                  </AniLink>
                </Col>
              ))}
        </Row>
      </section>
    </>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ({ language, readButton }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                author
                language
                date(formatString: "MMMM DD, YYYY")
                type
              }
            }
          }
        }
        authors: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "author-page" } } }
        ) {
          edges {
            node {
              frontmatter {
                name
                featuredimage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <BlogRoll
        postsData={data.posts}
        authorsData={data.authors}
        language={language}
        readButton={readButton}
      />
    )}
  />
);
