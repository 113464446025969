import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";


const PostImage = (props) => {
  const { alt = "", childImageSharp, image, type= "article" } = props;
  const getImageClassName = type === "article" ? "article-image" : "interview-image";

  if (!!image && !!image.childImageSharp) {
    return (
      <div className={`image-container ${getImageClassName}`}>
        <Img fluid={image.childImageSharp.fluid} alt={alt} />
      </div>
    );
  }

  if (!!childImageSharp) {
    return (
      <div className={`image-container ${getImageClassName}`}>
        return <Img fluid={childImageSharp.fluid} alt={alt} />;
      </div>
    );
  }

  if (!!image && typeof image === "string")
    return (
      <div className={`image-container ${getImageClassName}`}>
        <img src={image} alt={alt} />
      </div>
    );

  return null;
};

PostImage.propTypes = {
  alt: PropTypes.string.isRequired,
  childImageSharp: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  style: PropTypes.object,
};
export default PostImage;
